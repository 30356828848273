body {
  margin: 0;
  background-color: #f6f6f7;
  font-weight: 400 !important;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Poppins', sans-serif;
}

.bg-primary-500 {
  background-color: #006AA7 !important;
}

.neutral-700 {
  color: #616161 !important;
}

.neutral-900 {
  color: #0A0A0A !important
}
